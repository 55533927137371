/* Common imports for all pages */
@import 'abstract/all';

@import 'base/elements';
@import 'base/form';
@import 'base/fonts';
@import 'base/typography';
@import 'base/data-icon';
@import 'base/unread';
@import 'base/util';

@include theme-style;

@import 'layout/base';
@import 'layout/page-menu';

@import 'theme/board/chessground';

@import 'header/header';
@import '../../cli/css/clinput';

@import 'component/board';
@import 'component/box';
@import 'component/button';
@import 'component/user-link';
@import 'component/blind-mode';
@import 'component/friend-box';
@import 'component/signal';
@import 'component/power-tip';
@import 'component/mini-game';
@import 'component/btn-rack';
@import 'component/loader';
@import 'component/reconnecting';
@import 'component/announce';
@import 'component/dialog';
@import 'component/help';
