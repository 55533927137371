#announce {
  @extend %flex-between, %popup-shadow;

  position: fixed;
  bottom: 0;
  @include inline-start(0);
  font-size: 1.4em;
  background: $c-primary;
  color: $c-over;
  padding: 0.7rem 1rem;
  z-index: $z-tour-reminder-104;
  width: 100%;

  @media (min-width: at-least($xx-small)) {
    width: auto;
    border-top-right-radius: 3px;
  }

  a {
    color: $c-over;
  }

  time {
    margin-inline-start: 1rem;
  }

  .actions {
    a {
      font-weight: bold;
      margin-inline-start: 1rem;
    }
  }

  &.announce {
    flex-flow: row nowrap;
    background: $c-accent;
  }
}
