signal {
  display: inline-block;
  height: 1em;
  width: 1.5em;
  overflow: hidden;
  white-space: nowrap;
}

signal > i {
  width: 20%;
  margin-inline-start: 1px;
  display: inline-block;
  height: 40%;
  background-color: $m-secondary_dimmer--mix-82;
}

signal > i:nth-child(2) {
  height: 60%;
}

signal > i:nth-child(3) {
  height: 80%;
}

signal > i:nth-child(4) {
  height: 100%;
}

signal.q1 > i {
  background-color: $c-bad;
}

signal.q2 > i {
  background-color: $c-warn;
}

signal > i.off {
  background-color: $c-shade;
}
