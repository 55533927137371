.site-title {
  @extend %base-font, %page-text-shadow, %flex-center-nowrap;
  padding: 0 1rem;
  margin-inline-start: 0.5rem;
  gap: 0.75rem;
  font-size: 25px;
  transform: translateY(-3px);
  color: $c-font;

  @include mq-topnav-hidden {
    margin-inline-start: calc(0.5rem + $site-header-height);
  }

  @include mq-site-header-tall {
    font-size: 30px;
  }

  &:hover {
    color: $c-primary;

    span {
      color: $c-primary-dim;
    }
  }

  .site-name {
    display: none;
  }

  .site-icon {
    font-size: 24px;
    @media (min-height: at-least($tall)) {
      font-size: 36px;
    }
    display: none;
  }

  @media (max-width: at-least($xx-small)) {
    display: none;
  }

  @media (min-width: at-least($xx-small)) {
    .site-icon {
      display: block;
    }
  }

  @media (min-width: at-least(1180px)) {
    .site-name {
      display: block;
    }
    .site-icon {
      display: none;
    }
  }

  @media (min-width: at-least($xx-large)) {
    .site-icon {
      display: block;
    }
  }

  span {
    color: $c-font-dim;
  }

  .kiddo {
    color: $c-font-dimmer;
    font-weight: bold;
    margin-inline-end: 0.5em;
  }
}
