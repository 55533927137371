@include mq-topnav-hidden {
  .topnav-toggle {
    display: block;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .hbg {
    position: absolute;
    top: 0;
    @include inline-start(0);
    width: $site-header-height;
    height: $site-header-height;
    cursor: pointer;
    z-index: $z-topnav-111;

    &__in {
      &,
      &::after,
      &::before {
        display: block;
        margin-top: -1.5px;
        position: absolute;
        width: 23px;
        height: 2px;
        border-radius: 3px;
        background-color: $c-font-dim;

        @include mq-site-header-tall {
          width: 34px;
          height: 3px;
        }
      }

      top: 50%;
      @include inline-start(8.5px);

      @include mq-site-header-tall {
        @include inline-start(13px);
      }

      transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);

      &::after,
      &::before {
        content: '';
      }

      &::before {
        top: -6px;

        @include mq-site-header-tall {
          top: -9px;
        }

        transition:
          top 0.1s 0.25s ease-in,
          opacity 0.1s ease-in;
      }

      &::after {
        bottom: -7px;

        @include mq-site-header-tall {
          bottom: -10px;
        }

        transition:
          bottom 0.1s 0.25s ease-in,
          transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }

    .topnav-toggle:checked ~ & {
      position: fixed;
      background: $c-bg-high;

      .hbg__in {
        transform: rotate(225deg);
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before {
          top: 0;
          opacity: 0;
          transition:
            top 0.1s ease-out,
            opacity 0.1s 0.12s ease-out;
        }

        &::after {
          bottom: 0;
          transform: rotate(-90deg);
          transition:
            bottom 0.1s ease-out,
            transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
      }
    }
  }

  #topnav {
    @include if-transp {
      @include back-blur();
    }
    display: flex;
    flex-flow: row wrap;
    position: fixed;
    top: $site-header-height;
    bottom: 0;
    @include inline-start(0);
    background: $c-bg-high;

    transform: translateX(calc(-100% - 10px));
    @include if-rtl {
      transform: translateX(calc(100% + 10px));
    }
    transition: transform 200ms;

    padding-bottom: 1.2rem;
    overflow-y: auto;
    overscroll-behavior: contain;
    box-shadow: 2px 5px 7px hsla(0, 0, 0%, 0.5);
    border-radius: 0 3px 0 0;
    max-width: 80%;

    @include mq-safari {
      min-height: 75vh; // safari has funny ideas about fixed parents bounding fixed children
    }

    @media (min-width: at-least($xx-small)) {
      max-width: 70%;
    }

    a {
      color: $c-font;
      text-decoration: none;
      padding: 0.7em 0;
      opacity: 0;

      @include transition(opacity);
    }

    section {
      flex: 1 0 50%;
      margin-top: 1rem;
      z-index: $z-topnav-111;

      > a {
        font-size: 1.2em;
        padding-inline-start: 1.2rem;
        font-weight: bold;
        display: block;
      }

      .play {
        display: none;
      }

      div {
        display: flex;
        flex-flow: column;

        a {
          padding-inline-start: 2.4rem;
        }
      }

      a:active {
        background: $c-primary;
        color: $c-over;
      }
    }

    .topnav-toggle:checked ~ & {
      z-index: $z-topnav-111;
      transform: translateX(0);
      a {
        opacity: 1;
        transition: opacity 125ms ease-in-out 125ms;
      }
    }
  }

  body.masked {
    /* prevent scrolling while topnav is open */
    overflow: hidden;
  }
}
