.subnav {
  a {
    @extend %page-text;
    white-space: nowrap;
  }

  @include mq-subnav-top {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    &__inner {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 3px;
      overflow: auto clip;
      &::-webkit-scrollbar {
        display: none;
        width: 0 !important;
      }
    }

    a {
      font-family: 'Roboto', Sans-Serif;
      display: flex;
      align-items: center;
      color: $c-font;
      padding: 0.6rem;

      &:hover {
        color: $c-link;
      }

      &.active {
        color: $c-font-clear;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: -4px;
          height: 4px;
          right: 50%;
          transform: translate(50%, -50%);
          width: 100%;
          background: $c-accent;
          border-radius: 1em;
        }
      }
    }

    .sep {
      display: none;
    }
  }

  @include mq-subnav-side {
    margin-top: 5px;
    z-index: $z-subnav-side-2;

    /* active border must go over the page content */
    a {
      display: block;
      overflow: hidden;
      color: $c-font-page;
      padding: 0.7rem 2vw 0.7rem 0.8rem;
      position: relative;

      &::after {
        content: '';
        background: $m-accent--fade-40;
        width: 3px;
        position: absolute;
        height: 98%;
        top: 1%;
        @include inline-end(-1px);

        @include transition(all, 0.25s);

        transform: scale(0);
      }

      &.active,
      &:hover {
        &::after {
          transform: scale(1);
        }
      }

      &.active {
        color: $c-accent;
      }
    }

    .sep {
      height: 2em;
    }
  }
}
