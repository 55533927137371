.mini-game {
  @extend %nowrap-hidden;

  display: block;

  &,
  &:hover {
    color: $c-font;
  }

  &__player {
    @extend %flex-between-nowrap;
    @extend %page-text-shadow !optional;

    padding: 3px 2px 0 2px;

    &:first-child {
      padding: 0 2px 2px 2px;
    }
  }

  &__user {
    @extend %flex-center-nowrap;
    overflow: hidden;
  }

  &__flag {
    width: 1.3em;
    vertical-align: middle;
    opacity: 0.7;
    margin-inline-end: 1ch;
    .mini-game:hover & {
      opacity: 1;
    }
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rating {
    @extend %roboto;

    margin-inline-start: 1ch;
    font-size: 0.9em;
  }

  &__clock {
    @extend %roboto;

    padding-inline-start: 1ch;

    &.clock--run {
      color: $c-accent;
      font-weight: bold;
    }
  }

  &__result {
    font-weight: bold;
    margin: 0 1ch;
  }
}
