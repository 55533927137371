@include mq-topnav-visible {
  .hbg,
  .site-title-nav .fullscreen-mask,
  .topnav-toggle {
    display: none !important;
  }

  #topnav {
    ---nav-section: 26px;
    ---nav-section-hover: 35px;

    @include mq-site-header-tall {
      ---nav-section: 48px;
      ---nav-section-hover: 53px;
    }

    display: flex;

    section {
      position: relative;
      height: var(---nav-section);

      > a {
        @include if-light {
          text-shadow: 0 1px 0 $c-font-shadow;
        }
        @include if-transp {
          text-shadow: 0.5px 1px 1px #000;
        }
        color: $c-font-page;
        display: block;
        height: var(---nav-section);
        line-height: $site-header-height;
        padding: 0 0.7rem;
        text-transform: uppercase;
        border-inline-start: 2px solid transparent;
      }

      .home {
        display: none;
      }
    }

    div {
      visibility: hidden;
      max-height: inherit;
      position: absolute;
      @include inline-start(0);
      background: $c-bg-header-dropdown;
      min-width: 10rem;
      box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.3);
      border-radius: 0 3px 3px 3px;
      border-inline-start: 2px solid $c-primary;
      @include if-transp {
        @include back-blur();
      }

      a {
        display: block;
        padding: 0.6rem 0.7rem;
        color: $c-header-dropdown;

        &:hover {
          background: $c-primary;
          &,
          &::after {
            color: $c-over;
          }
        }

        &:first-child {
          border-radius: 0 3px 0 0;
        }

        &:last-child {
          border-radius: 0 0 3px 1px;
        }
      }
    }

    &.blind div {
      display: block;
      margin-inline-start: -9000px;
    }

    &.hover section:hover,
    section:active {
      > a {
        height: var(---nav-section-hover);
        background: $c-bg-header-dropdown;
        color: $c-header-dropdown;
        border-color: $c-primary;
      }

      div {
        visibility: visible;
        max-height: none;
      }
    }
  }
}
