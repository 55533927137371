$vp-min-width: 320px;
$vp-max-width: 1200px;

body {
  ---site-header-height: #{$site-header-short-height};
  ---site-header-margin: 0px;
  ---site-header-sticky-padding: calc((100vw - #{$site-header-sticky-max-width}) / 2);
  ---main-margin: 0px;
  ---sticky-gap: 0px;
  ---block-gap: 10px;

  @include mq-site-header-tall {
    ---site-header-height: #{$site-header-tall-height};
    ---sticky-gap: 0.5vmin;
  }

  &.header-margin {
    ---site-header-margin: #{$site-header-margin-extra};
  }

  @media (min-width: at-least($small)) {
    ---main-margin: 1vw;
    ---block-gap: 15px;
    margin-bottom: $block-gap;
  }
}

#main-wrap {
  display: grid;
  grid-template-areas: '. . main . .';
  grid-template-columns: $main-margin 1fr minmax(auto, var(---main-max-width)) 1fr $main-margin;
  ---main-max-width: #{$main-max-width};
  margin-top: $site-header-margin;

  @include mq-sticky-header {
    margin-top: $site-header-outer-height;
  }

  &.full-screen {
    ---main-max-width: auto;
  }

  &.full-screen-force {
    ---main-max-width: 100%;
  }

  @media (hover: none) {
    body.clinput & {
      display: none;
    }
  }
}

main {
  grid-area: main;

  &.page-small {
    max-width: 1000px;
    margin: auto;
    width: 100%;
  }
}
