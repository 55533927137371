.none {
  display: none !important;
}

.fullscreen-mask {
  @extend %fullscreen-mask;

  display: none;

  .fullscreen-toggle:checked ~ & {
    display: block;
  }
}

.rp::before {
  @extend %data-icon;

  margin-right: 0.2em;
  content: $licon-ArrowUpRight;
}

bad.rp {
  &::before {
    content: $licon-ArrowDownRight;
  }
}

good {
  color: $c-good;
}

bad {
  color: $c-bad;
}

.infinite-scroll .pager {
  text-align: center;
  margin: 10px auto;
}

.drop-target {
  border: 2px dashed $c-font;
  height: min-content;
}

.icon-flair {
  height: 1.1em;
  vertical-align: middle;
  margin-right: 0.5ch;
  transform: translateY(-0.1em);
  user-select: none;
}
