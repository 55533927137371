#clinput {
  display: flex;
  height: $site-header-height;

  input {
    @extend %page-text;

    line-height: $site-header-height;
    background: none;
    padding: 0;
    border: 0;
    width: 0;

    @media (min-width: at-least($x-large)) {
      @include transition(width);
    }

    body.clinput & {
      width: 20ch;
    }
  }

  input:focus {
    outline: none;
  }
}

body.clinput #top {
  @media (max-width: at-most($x-large)) {
    #topnav {
      display: none;
    }
  }

  @media (max-width: at-most($x-small)) {
    .site-title,
    .site-title-nav__donate {
      display: none;
    }
  }

  @media (max-width: at-most($xx-small)) {
    .site-buttons > *:not(#clinput) {
      display: none;
    }

    #clinput {
      width: 75vw;
    }
  }
}
