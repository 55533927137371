%top-icon {
  display: block;
  height: $site-header-height;
  line-height: $site-header-height;
}

.site-buttons {
  @extend %flex-center-nowrap;
  position: relative;

  .link {
    @extend %top-icon;
    color: $c-font;
    @include if-transp {
      @extend %page-text;
    }

    font-size: 1.1rem;
    padding: 0 20px;

    body[data-user] & {
      padding: 0 0.7rem;
    }

    &:hover {
      color: $c-font-clearer !important;
    }

    span::before {
      vertical-align: middle;
    }
  }

  .toggle {
    @extend %top-icon;
  }

  button.toggle {
    @extend %button-none;
  }

  .initiating {
    @extend %flex-center;

    justify-content: center;
    height: 300px;
    width: 225px;
  }

  .dropdown {
    @extend %dropdown-shadow;

    display: none;
    position: absolute;
    @include inline-end(0);
    top: $site-header-height;
    background: $c-bg-header-dropdown;
    z-index: $z-dropdown-109;

    a,
    button {
      color: $c-header-dropdown;
    }
  }

  .shown {
    .toggle {
      background: $c-bg-header-dropdown;
      color: $c-header-dropdown;
    }

    .dropdown {
      display: block;
    }
  }

  .signin {
    @extend %flex-center-nowrap;
    text-transform: uppercase;
    padding: 0 0.7rem;
  }

  .link-center {
    height: inherit;
    line-height: inherit;
  }

  .report-score {
    &:not(.report-score--high)::after {
      background: $c-bg-page;
      color: $c-brag;
      text-shadow: none;
      font-weight: normal;
    }
    &--low::after {
      color: $c-good !important;
    }
  }

  #warn-no-autoplay {
    display: none;
    padding: 0 0.8em;

    &.shown {
      display: block;
    }

    a {
      color: $c-bad;
    }

    a:hover {
      color: $m-bad_clearer--mix-80;
    }
  }

  .dasher {
    @extend %flex-center-nowrap;
  }
}

#user_tag {
  @extend %flex-center-nowrap;
  white-space: nowrap;
  gap: 0.5rem;

  &::after {
    @extend %data-icon;
    color: $m-font_dimmer--mix-85;
    content: $licon-AccountCircle;
    font-size: 2rem;
  }
}

#notify-app .initiating {
  width: 25rem;
}
